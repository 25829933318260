/**
 * Versionsinformationen
 */
export const revision = ".";
export const version  = "2024.1";

/**
 * URLs
 */
export const apiUrl = ( process.env.REACT_APP_API_ENDPOINT || "/api" );
export const wsUrl  = ( process.env.REACT_APP_WS_ENDPOINT || `${window.location.protocol == "https:" ? "wss" : "ws"}://${window.location.host}/ws` );
export const ssoUrl = ( process.env.REACT_APP_SSO_ENDPOINT || "/api/wissen" );
